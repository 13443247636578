const buttons = document.querySelectorAll(
  '[data-behaviour="toggle-directions-accordion"]'
);

//only toggles
buttons.forEach((button) => {
  button.addEventListener("click", function () {
    let parent = button.closest('[data-ref="directions-accordion"]');
    let classToSet = parent.dataset.class + "--open";
    parent.classList.toggle(classToSet);
  });
});
