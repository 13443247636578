(window => {
    const tabActive = "tabs__tab--active";
    const contentActive = "tabs__content--active";

    const getContainer = target => target.closest("[data-tabs]").dataset.tabs;
    
    const resetTabs = target =>
      [...document.querySelectorAll(`[data-tabs="${getContainer(target)}"] [data-tabs-link]`)].map(tab =>
        tab.classList.remove(tabActive)
      );
    
    const resetContent = target =>
      [...document.querySelectorAll(`[data-tabs="${getContainer(target)}"] [data-tabs-content]`)].map(tab =>
        tab.classList.remove(contentActive)
      );
    
    const selectTab = target => !target.classList.add(tabActive);
    
    const selectContent = (target, tid) =>
      document
        .querySelector(`[data-tabs="${getContainer(target)}"] [data-tabs-content="${tid}"]`)
        .classList.add(contentActive);
  
    return window.addEventListener(
      "click",
      ({ target: { dataset }, target }) =>
        dataset.behaviour === 'tabbed-content' &&
        resetTabs(target) &&
        resetContent(target) &&
        selectTab(target) &&
        selectContent(target, dataset.tabsLink)
    );
  })(window);