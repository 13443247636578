document.addEventListener('change', e => {
    if (!e.target.matches('[data-behaviour="card-grid-filter"]')) return;

    let url = window.location.origin + window.location.pathname;
    let urlSuffix = e.target.dataset.urlSuffix || '';

    //Need to remove anything after page reference so this works on paginated pages
    url = url.split('/page/')[0];

    let params = window.location.search ? window.location.search.replace('?', '').split('&') : [];

    params = params.reduce((output, value) => {
        const split = value.split('=');

        if (!split) return output;
        if (!!split[1] && split[1] !== '')output[split[0]] = split[1];

        return output;
    }, {});

    params[e.target.name] = e.target.value;

    const query = Object.keys(params).reduce((output, key) => {
        if (params[key]) output += key+'='+params[key] + '&';
        return output;
    }, '?').slice(0, -1);

    return window.location = url + query + urlSuffix;
});
