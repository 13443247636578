(window => {
    const matchHeight = () => {
        [...document.querySelectorAll('[data-match-height]')].map(container => {
            const els = [...container.querySelectorAll(container.dataset.matchHeight)];
    
            const height = els.map(el => el.getBoundingClientRect().height).reduce((output, height) => height > output ? height : output, 0);
    
            els.map(el => el.style.height = `${height}px`);
        })
    }

    document.addEventListener('DOMContentLoaded', matchHeight);
    window.addEventListener('resize', matchHeight);
})(window)