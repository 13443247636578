((window) => {
    const els = [...document.querySelectorAll('[data-behaviour="typed-text"]')];

    if (!els.length) return;

    const intervals = [];

    els.map((el, i) => {
        let currentStringIndex = 0;
        let currentLetterIndex = 0;
        let currentDirection = "forward";

        el.setAttribute("data-current-string-index", currentStringIndex);
        el.setAttribute("data-current-letter-index", currentLetterIndex);
        el.setAttribute("data-current-direction", currentDirection);

        const typingStrings = () => {
            const strings = el.dataset.text.split(",");
            let { currentStringIndex, currentLetterIndex, currentDirection } =
                el.dataset;

            if (currentDirection === "forward") {
                if (
                    parseInt(currentLetterIndex) <=
                    strings[parseInt(currentStringIndex)].length
                ) {
                    el.innerText = strings[parseInt(currentStringIndex)].slice(
                        0,
                        parseInt(currentLetterIndex)
                    );

                    el.setAttribute(
                        "data-current-letter-index",
                        parseInt(currentLetterIndex) + 1
                    );
                }

                if (currentLetterIndex == strings[currentStringIndex].length) {
                    el.setAttribute("data-current-direction", "backward");
                    clearInterval(intervals[i]);

                    setTimeout(() => {
                        intervals[i] = setInterval(typingStrings, 100);
                    }, 1000);
                }
            } else {
                if (parseInt(currentLetterIndex) >= 0) {
                    el.innerText = el.innerText.slice(
                        0,
                        parseInt(currentLetterIndex)
                    );
                    el.setAttribute(
                        "data-current-letter-index",
                        parseInt(currentLetterIndex) - 1
                    );
                }

                if (currentLetterIndex == 0) {
                    el.setAttribute("data-current-direction", "forward");
                    el.setAttribute("data-current-letter-index", 0);

                    if (currentStringIndex < strings.length - 1) {
                        el.setAttribute(
                            "data-current-string-index",
                            parseInt(currentStringIndex) + 1
                        );
                    } else {
                        el.setAttribute("data-current-string-index", 0);
                    }
                }
            }
        };

        intervals[i] = setInterval(typingStrings, 100);
    });
})(window);
