((window) => {
    // Plugin by CallumBW95 - https://codepen.io/callumbw95/pen/mdPXMag
    window.media = {};
    const responsiveHandler = (handler) => (e) => e.matches && handler();
    const responsive = (device, handler, init = true) =>
    (init && window.media[device].matches ? (handler() ? true : true) : true) &&
    window.media[device] &&
    media[device].addListener(responsiveHandler(handler));
    const createBreakpoint = (device, min = null, max = null) =>
    (window.media[device] = window.matchMedia(
        `${min ? `(min-width: ${min}px)` : ""}${min && max ? " and " : ""}${
        max ? `(max-width: ${max}px)` : ""
        }`
    ));
    
    const emailInput = document.querySelector('.footer__form .ginput_container_email input');

    if (!emailInput) return;

    const desktopPlaceholder = emailInput?.attributes.placeholder.value || '';

    emailInput.setAttribute('data-desktop-placeholder', desktopPlaceholder);
    emailInput.setAttribute('data-mobile-placeholder', 'Email');

    createBreakpoint("mobiledown", null, 767);
    createBreakpoint("mobileup", 768, null);

    responsive('mobileup', () => {
        emailInput.setAttribute('placeholder', emailInput.dataset.desktopPlaceholder);
        console.log('mobileup');
    });
    responsive('mobiledown', () => {
        emailInput.setAttribute('placeholder', emailInput.dataset.mobilePlaceholder)
        console.log('mobiledown');
    });

    return window;
})(window)

document.addEventListener('input', ({target: textarea}) => {
    if (!textarea.matches('.ginput_container_textarea .textarea')) return;

    textarea.style.height = 'auto';
    textarea.style.height = (textarea.scrollHeight) + "px";
});

const fixTextarea = () => document.querySelectorAll('.ginput_container_textarea .textarea').forEach(textarea => {
    textarea.setAttribute('rows', 1);
    textarea.style.height = textarea.scrollHeight + 'px';
    textarea.style.overflowY = 'hidden';
});

fixTextarea();