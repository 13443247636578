((window) => {
    const removeEmptyFromObject = (obj) =>
        Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
    const defaults = {};

    const getUniqueIdentifier = () => (Math.random() + 1).toString(36).substring(7);

    const getSettings = (carousel) => {
        if (!carousel.dataset.uniqueIdentifier) carousel.setAttribute('data-unique-identifier', getUniqueIdentifier());

        const options = {
            uniqueIdentifier: carousel.dataset.uniqueIdentifier,
            breakpoint: carousel.dataset.paginatedBreakpoint,
            mobileSlides: carousel.dataset.paginatedMobile,
            desktopSlides: carousel.dataset.paginatedDesktop,
            active: carousel.dataset.paginatedActive,
            gap: carousel.dataset.paginatedGap,
            pageClass: carousel.dataset.paginationPageClass,
            pageActive: carousel.dataset.paginationPageActive,
            pageWidth: carousel.dataset.paginationPageWidth,
            activePage: parseInt(carousel.dataset.paginationActivePage) || 1,
            paginatedAnimate: parseInt(carousel.dataset.paginatedAnimate) || false
        };

        const settings = {
            ...defaults,
            ...removeEmptyFromObject(options),
        };

        return settings;
    };

    const generateAnimation = (carousel, settings) => {
        if (!window.paginatedSlides) window.paginatedSlides = {};

        window.paginatedSlides[settings.uniqueIdentifier] = setInterval(() => {
            settings = getSettings(carousel);
            const slides = [...carousel.querySelectorAll('[data-paginated-slides] > *')];
            const currentPage = settings.activePage;
            const isDesktop = window.innerWidth > settings.breakpoint;
            const slidesCount = parseFloat(isDesktop
                ? settings.desktopSlides
                : settings.mobileSlides);
            const nextPage = (currentPage + 1) < (slides.length / slidesCount) + 1 ? currentPage + 1 : 1;
            console.log({currentPage, nextPage})

            if (!carousel.querySelector('[data-paginated-slides]').matches(':hover')) {
                goToPage(nextPage, carousel);
            }
        }, settings.paginatedAnimate);
    }

    const stopAnimation = (id) => window.paginatedSlides && window.paginatedSlides[id] && clearInterval(window.paginatedSlides[id]);

    const generatePagination = (carousel, settings, pages) => {
        const pagination = carousel.querySelector(
            "[data-paginated-pagination]"
        );

        pagination.innerHTML = "";

        if (pages === 1) return;

        for (let i = 1; i <= pages; i++) {
            const pageElement = document.createElement("button");
            const wrapperElement = document.createElement("li");

            pageElement.setAttribute(
                "data-behaviour",
                "update-paginated-slides-pagination"
            );
            pageElement.setAttribute("data-pagination-page", i);
            pageElement.innerText = i;
            pageElement.classList.add(settings.pageClass);

            if (i === settings.activePage)
                pageElement.classList.add(settings.pageActive);

            wrapperElement.appendChild(pageElement);
            pagination.appendChild(wrapperElement);
        }
    };

    const carouselSetup = () => {
        [
            ...document.querySelectorAll('[data-behaviour="paginated-slides"]'),
        ].map((carousel) => {
            const settings = getSettings(carousel);
            const wrapper = carousel.querySelector("[data-paginated-slides]");
            const slides = [...wrapper.querySelectorAll(":scope > *")];
            carousel.style.maxWidth = 'unset';

            carousel.classList.remove(settings.active);
            wrapper.style.width = "unset";

            const isDesktop = window.innerWidth > settings.breakpoint;
            const slidesCount = parseFloat(isDesktop
                ? settings.desktopSlides
                : settings.mobileSlides);

            if (!slidesCount) return generatePagination(carousel, settings, 1);

            const pages = Math.ceil(slides.length / slidesCount);
            settings.pageWidth = wrapper.getBoundingClientRect().width;
            carousel.setAttribute("data-pagination-page-width", settings.pageWidth);
            carousel.style.maxWidth = `${settings.pageWidth}px`;

            const slideWidth =
                slidesCount === 1
                    ? settings.pageWidth
                    : (settings.pageWidth - settings.gap * (slidesCount - 1)) / slidesCount;

            const slidesWidth =
                slideWidth * slides.length + (settings.gap * (slides.length - 1));

            wrapper.style.width = `${slidesWidth}px`;
            carousel.classList.add(settings.active);

            wrapper.style.transform = `translateX(-${
                (settings.activePage - 1) * settings.pageWidth +
                (settings.activePage - 1) * settings.gap
            }px)`;

            if (settings.paginatedAnimate) {
                stopAnimation(settings.uniqueIdentifier);
                generateAnimation(carousel, settings);
            }

            if (settings.pageClass) generatePagination(carousel, settings, pages);
        });
    };

    const getPageWidth = (slideWidth, slidesCount, settings) => {
        let pageWidth;

        switch (true) {
            case slidesCount === Math.floor(slidesCount) && slidesCount !== 1:
                pageWidth = parseFloat(settings.pageWidth) + parseFloat(settings.gap);
                break;
            case slidesCount === 1:
                pageWidth = Math.floor(slidesCount) * (parseFloat(slideWidth) + (1 * parseFloat(settings.gap)));
                break;
            default:
                pageWidth = (parseFloat(settings.pageWidth) / slidesCount) + (parseFloat(settings.gap) / slidesCount);                
        }

        return pageWidth;
    }

    const getSlideWidth = (slidesCount, carousel, settings) => 
        slidesCount == 1
            ? carousel.getBoundingClientRect().width
            : (carousel.getBoundingClientRect().width -
                    settings.gap * (slidesCount - 1)) /
                slidesCount;

    const goToPage = (page, carousel) => {
        let settings = getSettings(carousel);
        const pagination = carousel.querySelector("[data-paginated-pagination]");
        const wrapper = carousel.querySelector("[data-paginated-slides]");
        const isDesktop = window.innerWidth > settings.breakpoint;
        const slidesCount = parseFloat(isDesktop
            ? settings.desktopSlides
            : settings.mobileSlides);
        const slideWidth = getSlideWidth(slidesCount, carousel, settings);
        const pageWidth = getPageWidth(slideWidth, slidesCount, settings);

        if (pagination) [...pagination.querySelectorAll("button")].map((page) =>
            page.classList.remove(settings.pageActive)
        );

        if (settings.pageClass) carousel.querySelector(`[data-pagination-page="${page}"]`)?.classList.add(settings.pageActive);

        wrapper.style.transform = `translateX(-${(page - 1) * pageWidth}px)`;
        carousel.setAttribute("data-pagination-active-page", page);
    };

    document.addEventListener("DOMContentLoaded", carouselSetup);
    window.addEventListener("resize", () => setTimeout(carouselSetup, 300));

    document.addEventListener("click", (e) => {
        if (!e.target.matches('[data-behaviour="update-paginated-slides-pagination"]')) return;

        const carousel = e.target.closest(
            '[data-behaviour="paginated-slides"]'
        );

        if (!carousel) return;

        const page = e.target.dataset.paginationPage;

        goToPage(page, carousel);
    });
})(window);
