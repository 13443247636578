// Search Toggle
document.addEventListener('click', e => {
    if (!e.target.matches('[data-behaviour="toggle-header-search"]')) return;

    const header = e.target.closest('.header');

    if (!header) return;

    header.classList[header.matches('.header--search-active') ? 'remove' : 'add']('header--search-active');
    header.querySelector('input#search').focus();
});

// Search clear
document.addEventListener('click', e => {
    if (!e.target.matches('[data-behaviour="clear-header-search"]')) return false;

    const searchbar = e.target.closest('.header__searchbar');

    searchbar.querySelector('input#search').value = '';
});

const submitSearch = (searchbar) => {
    const query = searchbar.querySelector('input').value;

    if (query.length >= 2) window.location = '/?s=' + encodeURI(query);
}

// Enter Search
document.addEventListener('click', e => {
    if (!e.target.matches('[data-behaviour="submit-header-search"]')) return false;

    const searchbar = e.target.closest('.header__searchbar');

    submitSearch(searchbar);
});

document.addEventListener('keypress', e => {
    if (e.key !== "Enter") return false;

    const searchbar = document.querySelector('.header__searchbar');
    if (searchbar.querySelector('input[name="search"]') !== document.activeElement) return false;

    submitSearch(searchbar);
});

const closeAllOpenSubMenus = () => [...document.querySelectorAll('.navigation__item--active')].map(item => item.classList.remove('navigation__item--active'));

// Mobile Menu Toggle
document.addEventListener('click', e => {
    if (!e.target.matches('[data-behaviour="toggle-header-menu"]')) return;

    const header = e.target.closest('.header');

    if (!header) return;

    const isMenuOpen = header.matches('.header--menu-active');

    if (isMenuOpen) {
        header.classList.remove('header--menu-active');
        closeAllOpenSubMenus();
    } else {
       header.classList.add('header--menu-active');
    }
});

// Mobile Sub Nav child Links
document.addEventListener('click', e => {
    if (!e.target.matches('[data-behaviour="menu-item-children"]')) return;

    e.preventDefault();
    
    const item = e.target.closest('.navigation__item');

    if (!item) return;

    item.classList.add('navigation__item--active');
});

// Mobile Sub Nav parent Links
document.addEventListener('click', e => {
    if (!e.target.matches('[data-behaviour="menu-item-back"]')) return;
    
    const item = e.target.closest('.navigation__item');

    if (!item) return;

    item.classList.remove('navigation__item--active');
});