// taken from https://codepen.io/callumbw95/pen/XWbgoqo

(window => {
	const activeClass = "accordion__row--active";
	const getContainer = target => target.closest("[data-accordion-container]").dataset.accordionContainer;
	const getRow = target => target.closest("[data-accordion-row]");
	const resetRows = target => [...document.querySelectorAll(`[data-accordion-container="${getContainer(target)}"] [data-accordion-row]`)].filter(el => el !== getRow(target)).map(el => el.classList.remove(activeClass));
	const selectRow = target => !getRow(target).classList[getRow(target).matches(`.${activeClass}`) ? 'remove' : 'add'](activeClass);
	
	return window.addEventListener(
		"click",
		({ target: { dataset }, target }) =>
			dataset.accordion &&
			resetRows(target) &&
			selectRow(target)
	);
})(window);
	